<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" content="编辑顾客意见咨询" class="margin-bottom margin-top-xs"></el-page-header>
		<el-divider content-position="left">基本信息</el-divider>
		<div>
			<div class="form_box">
				<el-form ref="formOne" :model="formOne" label-width="100px" class="flex flex-wrap" v-loading="loading">

					<el-form-item label="姓名" prop="name" :rules="rules.required">
						<div class="form-item-box">
							<el-input v-model="formOne.name" placeholder="请填写顾客姓名"></el-input>
						</div>
					</el-form-item>

					<el-form-item label="填写日期" prop="created_at" :rules="rules.required">
						<div class="form-item-box">

							<el-date-picker style="width: 100%;" v-model="formOne.created_at" type="datetime"
								format="yyyy-MM-dd hh:mm:ss" value-format="yyyy-MM-dd hh:mm:ss" placeholder="选择日期时间">
							</el-date-picker>
						</div>
					</el-form-item>

					<el-form-item label="联系电话" prop="phone" :rules="[rules.required,rules.phone]">
						<div class="form-item-box">
							<el-input v-model="formOne.phone" placeholder="请填写顾客联系电话"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="记录人" prop="operater_name" :rules="rules.required">
						<div class="form-item-box">
							<el-input v-model="formOne.operater_name" placeholder="请填写记录人"></el-input>
						</div>
					</el-form-item>

				</el-form>
			</div>
			<el-divider content-position="left">详细资料</el-divider>
			<div class="form_box">
				<el-form ref="formTwo" :model="formTwo" label-width="300px" label-position="left"
					style="margin-left: 50px;">

					<el-form-item label="1、门店的地方好找吗？" prop="one" :rules="rules.required">
						<el-radio-group v-model="formTwo.one">
							<el-radio :label="5">很好</el-radio>
							<el-radio :label="4">较好</el-radio>
							<el-radio :label="3">一般</el-radio>
							<el-radio :label="2">较差</el-radio>
							<el-radio :label="1">很差</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="2、内部之指示寻找标志足够吗？" prop="two" :rules="rules.required">
						<el-radio-group v-model="formTwo.two">
							<el-radio :label="5">很好</el-radio>
							<el-radio :label="4">较好</el-radio>
							<el-radio :label="3">一般</el-radio>
							<el-radio :label="2">较差</el-radio>
							<el-radio :label="1">很差</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="3、内部环境是否整洁适度？" prop="three" :rules="rules.required">
						<el-radio-group v-model="formTwo.three">
							<el-radio :label="5">很好</el-radio>
							<el-radio :label="4">较好</el-radio>
							<el-radio :label="3">一般</el-radio>
							<el-radio :label="2">较差</el-radio>
							<el-radio :label="1">很差</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="4、前台接待态度是否令阁下满意？" prop="four" :rules="rules.required">
						<el-radio-group v-model="formTwo.four">
							<el-radio :label="5">很好</el-radio>
							<el-radio :label="4">较好</el-radio>
							<el-radio :label="3">一般</el-radio>
							<el-radio :label="2">较差</el-radio>
							<el-radio :label="1">很差</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="5、环境是否干净？" prop="five" :rules="rules.required">
						<el-radio-group v-model="formTwo.five">
							<el-radio :label="5">很好</el-radio>
							<el-radio :label="4">较好</el-radio>
							<el-radio :label="3">一般</el-radio>
							<el-radio :label="2">较差</el-radio>
							<el-radio :label="1">很差</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="6、老师之仪容是否整洁？" prop="six" :rules="rules.required">
						<el-radio-group v-model="formTwo.six">
							<el-radio :label="5">很好</el-radio>
							<el-radio :label="4">较好</el-radio>
							<el-radio :label="3">一般</el-radio>
							<el-radio :label="2">较差</el-radio>
							<el-radio :label="1">很差</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="7、工作态度是否令你满意？" prop="seven" :rules="rules.required">
						<el-radio-group v-model="formTwo.seven">
							<el-radio :label="5">很好</el-radio>
							<el-radio :label="4">较好</el-radio>
							<el-radio :label="3">一般</el-radio>
							<el-radio :label="2">较差</el-radio>
							<el-radio :label="1">很差</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="8、老师的技术是否令你满意？" prop="eight" :rules="rules.required">
						<el-radio-group v-model="formTwo.eight">
							<el-radio :label="5">很好</el-radio>
							<el-radio :label="4">较好</el-radio>
							<el-radio :label="3">一般</el-radio>
							<el-radio :label="2">较差</el-radio>
							<el-radio :label="1">很差</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="9、老师有否提供在家中如何进行家庭护理？" prop="nine" :rules="rules.required">
						<el-radio-group v-model="formTwo.nine">
							<el-radio :label="5">很好</el-radio>
							<el-radio :label="4">较好</el-radio>
							<el-radio :label="3">一般</el-radio>
							<el-radio :label="2">较差</el-radio>
							<el-radio :label="1">很差</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="10、在与老师交谈中有否令你感到不满意？" prop="ten" :rules="rules.required">
						<el-radio-group v-model="formTwo.ten">
							<el-radio :label="5">很好</el-radio>
							<el-radio :label="4">较好</el-radio>
							<el-radio :label="3">一般</el-radio>
							<el-radio :label="2">较差</el-radio>
							<el-radio :label="1">很差</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="11、老师助理的工作态度是否令你满意？" prop="eleven" :rules="rules.required">
						<el-radio-group v-model="formTwo.eleven">
							<el-radio :label="5">很好</el-radio>
							<el-radio :label="4">较好</el-radio>
							<el-radio :label="3">一般</el-radio>
							<el-radio :label="2">较差</el-radio>
							<el-radio :label="1">很差</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="12、收款时速度及态度是否令你满意？" prop="twelve" :rules="rules.required">
						<el-radio-group v-model="formTwo.twelve">
							<el-radio :label="5">很好</el-radio>
							<el-radio :label="4">较好</el-radio>
							<el-radio :label="3">一般</el-radio>
							<el-radio :label="2">较差</el-radio>
							<el-radio :label="1">很差</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="13、您在做服务过程中是否感觉服务舒适？" prop="thirteen" :rules="rules.required">
						<el-radio-group v-model="formTwo.thirteen">

							<el-radio :label="5">很好</el-radio>
							<el-radio :label="4">较好</el-radio>
							<el-radio :label="3">一般</el-radio>
							<el-radio :label="2">较差</el-radio>
							<el-radio :label="1">很差</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="14、你对改善本店工作的看法和意见？" prop="fourteen" :rules="rules.required">
						<el-radio-group v-model="formTwo.fourteen">
							<el-radio :label="5">很好</el-radio>
							<el-radio :label="4">较好</el-radio>
							<el-radio :label="3">一般</el-radio>
							<el-radio :label="2">较差</el-radio>
							<el-radio :label="1">很差</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="onSubmit()">保存</el-button>
						<el-button @click="$router.back()">取消</el-button>
					</el-form-item>
				</el-form>
			</div>

		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../../common/common.rules.js";

	export default {
		data() {
			return {
				loading: false,
				rules,
				formOne: {
					id: this.$route.params.id,
					name: "",
					phone: '',
					operater_name: '',
					created_at: '',
				},
				formTwo: {
						one: "",
						two: '',
						three: '',
						four: "",
						five: '',
						six: '',
						seven: '',
						eight: "",
						nine: '',
						ten: '',
						eleven: '',
						twelve: '',
						thirteen: '',
						fourteen: '',
					
				},

			};
		},
		computed: {

		},
		mounted() {
			if (this.formOne.id) {
				this.getInfo()
			}
			
			
		},
		methods: {
			//获取客户意详情
			getInfo() {
				this.$api.tablewx.getCustomerConsultingInfo({
					id: this.formOne.id
				}).then(res => {
					this.formOne=res.data
					this.formTwo.one=res.data.item_list[0].score
					this.formTwo.two=res.data.item_list[1].score
					this.formTwo.three=res.data.item_list[2].score
					this.formTwo.four=res.data.item_list[3].score
					this.formTwo.five=res.data.item_list[4].score
					this.formTwo.six=res.data.item_list[5].score
					this.formTwo.seven=res.data.item_list[6].score
					this.formTwo.eight=res.data.item_list[7].score
					this.formTwo.nine=res.data.item_list[8].score
					this.formTwo.ten=res.data.item_list[9].score
					this.formTwo.eleven=res.data.item_list[10].score
					this.formTwo.twelve=res.data.item_list[11].score
					this.formTwo.thirteen=res.data.item_list[12].score
					this.formTwo.fourteen=res.data.item_list[13].score
					
					
				})
			},

			onSubmit() {
				const formOne = new Promise((resolve) => {
					this.$refs.formOne.validate(valid => {
						if (valid) resolve()
					})
				})
				const formTwo = new Promise((resolve) => {
					this.$refs.formTwo.validate(valid => {
						if (valid) resolve()
					})
				})

				Promise.all([formOne, formTwo]).then(() => {
					const loading = this.$loading();
					this.$api.tablewx.customerConsultingAdd({
						id: this.formOne.id,
						name: this.formOne.name,
						phone: this.formOne.phone,
						operater_name: this.formOne.operater_name,
						created_at: this.formOne.created_at,
						item_score: {
							"1": this.formTwo.one,
							"2": this.formTwo.two,
							"3": this.formTwo.three,
							"4": this.formTwo.four,
							"5": this.formTwo.five,
							"6": this.formTwo.six,
							"7": this.formTwo.seven,
							"8": this.formTwo.eight,
							"9": this.formTwo.nine,
							"10": this.formTwo.ten,
							"11": this.formTwo.eleven,
							"12": this.formTwo.twelve,
							"13": this.formTwo.thirteen,
							"14": this.formTwo.fourteen,
						}
					}).then(res => {
						loading.close();
						if (res.code == 200) {
							// 保存成功
							this.$message.success(`保存成功`);
							this.$router.back();
						} else {
							this.$message.error(res.msg);
						}
					}).catch(err => {
						loading.close();
						this.$alert(err);
					});

				})

				// this.$refs.form.validate(valid => {
				// 	if (valid) {


				// 	} else {
				// 		this.$message.warning(`请检查输入错误`);
				// 		return false;
				// 	}
				// });
			}
		},

	};
</script>
<style scoped lang="scss">
	.form_box {
		max-width: 800px;
	}

	.form-item-box {
		width: 300px
	}
</style>
